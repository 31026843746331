import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Global } from '../api/Global';
import { API } from '../api/API';
import ConfirmBox from '../ui/ConfirmBox';
class PostHeader extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      confirmBoxMsg: null,
      id: null,
      img: null,
      title: null,
      updatedAt: null,
      from: null,
      creatorName: null,
      showConfirmBox: false,
      isCommentable: false,
      isPinned: false,
      isSchedule: false,
      pinnedBy: null,
    };
  }
  showConfirmBox(val) {
    try {
      this.setState({
        showConfirmBox: true,
        confirmBoxMsg: val,
      });
    } catch (error) {
      console.log(error);
    }
  }
  componentDidMount() {
    try {
      this.setState({
        isCommentable: this.props.isCommentable,
        id: this.props.id ? this.props.id : null,
        img: this.props.img ? this.props.img : null,
        title: this.props.title ? this.props.title : null,
        updatedAt: this.props.updatedAt ? this.props.updatedAt : null,
        from: this.props.from ? this.props.from : null,
        creatorName: this.props.creatorName ? this.props.creatorName : null,
        isPinned: this.props.isPinned ? this.props.isPinned : false,
        isSchedule: this.props.isSchedule ? this.props.isSchedule : false,
        showConfirmBox: this.props.showConfirmBox
          ? this.props.showConfirmBox
          : false,
        isCommentable: this.props.isCommentable
          ? this.props.isCommentable
          : false,
        pinnedBy: this.props.pinnedBy,
      });
    } catch (error) {
      console.log(error);
    }
  }

  donotRemovePost() {
    try {
      this.setState({
        showConfirmBox: false,
      });
    } catch (error) {
      console.log(error);
    }
  }
  selectedCommentStatus(val) {
    try {
      let obj = {
        id: this.props.id,
        status: val,
      };
      API.setPostCommentStatus(obj)
        .catch((error) => {
          console.log(error);
        })
        .then((response) => {
          if (response && response.data && response.data.data) {
            if (val === true) {
              this.props.onAlert('Commenting for user is activated');
              this.setState({
                isCommentable: true,
              });
            } else {
              this.props.onAlert('Commenting for user is deactivated');
              this.setState({
                isCommentable: false,
              });
            }
          }
        });
    } catch (error) {
      console.log(error);
    }
  }
  isAuthorizeUser() {
    try {
      let status = true;
      let user = Global.user();

      if (user === 'learner') {
        status = false;
      }

      return status;
    } catch (error) {
      console.log(error);
    }
  }
  cancelRemovePost() {
    this.setState({
      showConfirmBox: false,
    });
  }
  async pinPostHandler() {
    try {
      let result = await API.pinTogglePost({
        id: this.state.id,
        status: this.state.isPinned,
      });
      if (result) {
        this.props.onCrawlPostList();
      }
    } catch (error) {
      throw new Error(error);
    }
  }
  removePost() {
    this.setState(
      {
        showConfirmBox: false,
      },
      () => {
        this.props.onRemovePost(this.state.id);
      },
    );
  }
  render() {
    const {
      id,
      img,
      title,
      updatedAt,
      from,
      creatorName,
      showConfirmBox,
      isCommentable,
      isPinned,
      pinnedBy,
    } = this.state;
    return (
      <>
        {showConfirmBox ? (
          <ConfirmBox
            onCancel={() => this.cancelRemovePost()}
            onOK={() => this.removePost()}
            msg={this.state.confirmBoxMsg}
          />
        ) : (
          ''
        )}

        <header>
          <div className="leftSide">
            <img
              className="tinyimg"
              src={img || '/img/logo.png'}
              alt={creatorName || ''}
            />
            <div className="shortUserInfo">
              <h3>{title}</h3>

              <div className="timeago">
                {updatedAt} . {from}
              </div>
            </div>
          </div>
          <div className="rightSide">
            {Global.user() === 'admin' ? (
              <button className="link">
                <img
                  src="/img/edit.svg"
                  alt="Edit"
                  title="Edit"
                  onClick={() => this.props.onShowEditPost(id)}
                />
              </button>
            ) : (
              ''
            )}

            {this.isAuthorizeUser() ? (
              <div className="link options">
                <img src="/img/options.svg" alt="Options" title="Options" />
                <div className="optionsPop">
                  <h4>Post Options</h4>
                  <div className="item">
                    <button
                      className={isCommentable === true ? 'active' : ''}
                      onClick={() => this.selectedCommentStatus(true)}
                    >
                      <span></span>
                      Comment On
                    </button>
                  </div>
                  <div className="item">
                    <button
                      onClick={() => this.selectedCommentStatus(false)}
                      className={isCommentable === false ? 'active' : ''}
                    >
                      <span></span>
                      Comment Off
                    </button>
                  </div>

                  {Global.user() === 'admin' && isPinned === false ? (
                    <div className="item">
                      <button
                        className="linkonly"
                        onClick={() =>
                          this.showConfirmBox(
                            'Are you sure you want to remove post ?',
                          )
                        }
                      >
                        Remove Post
                      </button>
                    </div>
                  ) : (
                    ''
                  )}

                  {Global.user() === 'admin' &&
                    pinnedBy !== 'system' &&
                    this.state.isSchedule === false && (
                      <div className="item">
                        <button
                          className="linkonly"
                          onClick={() => this.pinPostHandler()}
                        >
                          {isPinned ? 'Unpin Post' : 'Pin Post'}
                        </button>
                      </div>
                    )}
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </header>
      </>
    );
  }
}

PostHeader.propTypes = {
  img: PropTypes.string,
  from: PropTypes.string,
  onConfirmBox: PropTypes.func,
  onShowEditPost: PropTypes.func,
};

export default React.memo(PostHeader);
